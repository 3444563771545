import React from "react";
import "./subcourselText.css";

const subcourselText = ({ data }) => {
  return (
    <div className="subcourselText">
      <div className="text1">
        <span id="key">{data[0].key}</span>
        <p id="para">{data[0].para}</p>
      </div>
      <div className="text1">
        <span id="key">{data[1].key}</span>
        <p id="para">{data[1].para}</p>
      </div>
      <div className="text1">
        <span id="key">{data[2].key}</span>
        <p id="para">{data[2].para}</p>
      </div>
    </div>
  );
};

export default subcourselText;
