import React from "react";
import SubcourselText from "./subCoursel_text/subcourselText";
import "../component3/Component.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css"; 

const Component3 = ({ array1, array2, array3, data }) => {
  return (
    <div className="comp3-main">
      <div className="comp3-head">
        <div id="heading">Best practices</div>
        <div id="sub-heading">How top performers use {data[0] && data[0].normalReplace}</div>
      </div>
      <CarouselProvider
        naturalSlideWidth={50}
        // naturalSlideHeight={50}
        isIntrinsicHeight = "true"
        
        totalSlides={3}
      >
        <Slider >
     
            <Slide item xs={6} >
              <SubcourselText
                  data = {array1}
              />
            </Slide>
            <Slide item xs={6}>
              <SubcourselText
                  data = {array2}
              />
            </Slide>
            <Slide item xs={6}>
              <SubcourselText
                  data = {array3}
              />
            </Slide>
            
        </Slider>

        <DotGroup />

      </CarouselProvider>
      
    </div>
  );
};

export default Component3;
