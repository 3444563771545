import React from "react";
import vs from "../../Assets/vs.png";
import "./component2.css";
import sliderGreen from "../../Assets/slider-green.png";
import trophie from '../../Assets/trophie.png';

const component2 = ({ app, performance, data }) => {
  const sliderStyle = {
    width: `${(app / performance) * 100}vw`,
    height: "10px",
    position: "absolute",
  };

  var marginLeft = (app / performance) * 100 -2



  const circleStyle = {
    

    marginLeft: marginLeft > 76 ?  "76vw" : `${marginLeft}vw`,

    marginTop: "-2.5px",
    height: "15px",
    width: "15px",
  };

  return (
    <div className="component-2">
      <div id="heading">{data[0] && data[0].topText} given last week</div>
      <div id="sub-heading" className="sub">Compare your usage with the top performers</div>
      <div className="comp2-card">
        <div className="card-top">
          <div className="yourApp">
            <h2>{app}</h2>
            <p>Your App</p>
          </div>
 
          <img src={vs} id="vs" />
          <div className="yourPerformance">
            <h2>{performance}</h2>
            <p>Top Performers</p>
          </div>
        </div>
        <div className="com2-slider">
          {/* <img src={sliderGreen} style={sliderStyle} id="slider" /> */}
          <div className="green-rectangle" style={sliderStyle}></div>
          <div className="green-circle" style={circleStyle}></div>
          <img src={trophie} id="trophie"/>
        </div>
        <div className="card-bottom">
          <span>Top performers give {performance} {data[0] && data[0].normalReplace} in a week</span>
        </div>
      </div>
    </div>
  );
};

export default component2;
