import React from "react";
import "./subcoursel.css";

import play from '../../../Assets/play.png';
import cross from '../../../Assets/x-mark.png';

import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const Subcoursel = ({ para1, para2, videoLink, image, backgroundImg }) => {


  const subcourselStyle = {
    backgroundImage: `url(${backgroundImg})`,

  }

  const [open, setOpen] = React.useState(false)


  return (
    <>
      <div className="subcoursel" style={subcourselStyle} >
        <div className="sub-left">
          <h4>{para1}</h4>
          <p>{para2}</p>
          <button onClick={() => setOpen(true)} >
            <img src={play} />
          Watch Video</button>
        </div>

        <div className="sub-right">
          <img src={image} id="coursel-image" />
        </div>
      </div>

      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='small'
      >
        <iframe id="iframe" src={videoLink} allowFullScreen frameBorder="1" width="100%" style={{ height: "40vh" }}></iframe>
        <img src={cross} id="cross" onClick={() => setOpen(false)} />
      </Modal>
    </>
  );
};

export default Subcoursel;
