import React from 'react'
import './Component4.css';
import comp4Image from '../../Assets/comp4.png'
import call from '../../Assets/call.png'
import chat from '../../Assets/chat.png'

const Component4 = (props) => {
    return (
        <div className="component4">

            <div className="comp4-left">
                <p id="comp4-head">Your CSM can guide you on how to use {props.data[0] && props.data[0].normalReplace}</p>
                <div className="comp4-btn">
                    <button onClick={() => props.helpAndSupport(8588025648)}> <img src={call} /> Call Now</button>
                    <button onClick={() => props.helpAndSupportWhatsapp("Hi",918588809776)}> <img src={chat} /> Help & Support</button>
                </div>
            </div>

            <div className="comp4-right">
                <img src={comp4Image} style={{width: "100%",marginTop: "15%"}}/>
            </div>
            
        </div>
    )
}

export default Component4
