import React from 'react'
import './Component5.css'
import Subcorouselorg from './subcorousel_org/Subcorousel_org';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
  } from "pure-react-carousel";
  import "pure-react-carousel/dist/react-carousel.es.css";


const Component5 = ({ comp5Data, data }) => {


    

    return (
        <div className="comp5-main">
            <div id="heading">What other tutors are saying</div>
            <div id="sub-heading">How {data[0] && data[0].normalReplace} feature helped them grow</div>

        <CarouselProvider
        naturalSlideWidth={50}
        // naturalSlideHeight={50}
        totalSlides={comp5Data.length}
        isIntrinsicHeight={true}
      >
        <Slider style={{ height: "145px;" }}>
          {comp5Data.map((item) => (
            <Slide  xs={6}>
              <Subcorouselorg data={item}
                
              />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>


        </div>
    )
}

export default Component5;
