import Coursel1Bg1 from "../Assets/Coursel1Bg1.jpg";
import Coursel1Bg2 from "../Assets/Coursel1Bg2.jpg";
import Coursel1Bg3 from "../Assets/Coursel1Bg3.jpg";

import orgBackgroundImg1 from "../Assets/comp5Background1.png";
import orgBackgroundImg2 from "../Assets/comp5Background2.png";
import orgBackgroundImg3 from "../Assets/comp5Background3.png";
import orgBackgroundImg4 from "../Assets/comp5Background4.png";

import liveClassImg from "../Assets/liveClassImg.png";
import assignmentImg from "../Assets/assignmentImg.png";
import onlineTestImg from "../Assets/testImg.png";
import batchVideosImg from "../Assets/batchVideosImg.png";
import notificationsImg from "../Assets/notificationsImg.png";
import chatsImg from "../Assets/chatsImg.png";

import DefaultorgImage from "../Assets/OrgLogo.png";
const infinityCoaching =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Infinity-coaching.jpg";
const bodhiSattava =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Bodhi-sattava-classes.jpg";
const starHybrid =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/star-hybrid.jpg";

const gyanin =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Gyanin.jpg";
const KKCommerceAcademy =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/KK-Commerce-academy.png";
const saraswatiScienceTutorial =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/saraswati-science-tutorial.jpg";

const get99percentile =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/get99percentile.jpg";
const synodicInstitute =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Synodic.png";
const mathsMadeEasy =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Maths-made-easy.jpg";

const badalaClasses =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Badala-classes.jpg";
const mathsPedia =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/Mathspedia.jpg";
const SAMEducationAcademy =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/sam-education-academy.jpg";

const featureTypeEnum = {
  LiveClasses: "LIVECLASSES",
  Assignments: "ASSIGNMENT",
  OnlineTests: "ONLINETEST",
  BatchVideos: "BATCHVIDEOS",
  Notifications: "NOTIFICATIONS",
  Chats: "CHATS",
};

export const returnFeatureData = (featureType) => {
  switch (featureType) {
    case featureTypeEnum.LiveClasses:
      return [
        {
          image: liveClassImg,
          topText: "Live Classes",
          normalReplace: "live classes",
          bottomText: "What are live classes in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/4VxdP73Z6TE",
          CTAtext: "Go Live Now",
        },
        {
          image: liveClassImg,
          topText: "Live Classes",
          normalReplace: "live classes",
          bottomText: "How to use live class for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/xlqX1pxoX04",
        },
        {
          image: liveClassImg,
          topText: "Live Classes",
          normalReplace: "live classes",
          bottomText: "How other teachers are using live class?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/u95OtOKHQok",
        },
      ];
    case featureTypeEnum.Assignments:
      return [
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "What are assignments in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/PqW-MP478sA",
          CTAtext: "Assign Now",
        },
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "How to use assignment for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/ojdWw4wYbnA",
        },
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "How other teachers are using assignments?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/6Kmb_iW1vR4",
        },
      ];
    case featureTypeEnum.OnlineTests:
      return [
        {
          image: onlineTestImg,
          topText: "Online Tests",
          normalReplace: "online tests",
          bottomText: "What are online tests in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/s_WoWEGO6cU",
          CTAtext: "Assign Test Now",
        },
        {
          image: onlineTestImg,
          topText: "Online Tests",
          normalReplace: "online tests",
          bottomText: "How to use online test for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/-1cnUfmxR4g",
        },
        {
          image: assignmentImg,
          topText: "Online Tests",
          normalReplace: "online tests",
          bottomText: "How other teachers are using online test?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/kLfxyx026TM",
        },
      ];
    case featureTypeEnum.BatchVideos:
      return [
        {
          image: batchVideosImg,
          topText: "Batch Videos",
          normalReplace: "batch videos",
          bottomText: "What are batch videos in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/freG_ftS3jU",
          CTAtext: "Upload Now",
        },
        {
          image: batchVideosImg,
          topText: "Batch Videos",
          normalReplace: "batch videos",
          bottomText: "How to use batch videos for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/EEcb0ZbFolI",
        },
        {
          image: batchVideosImg,
          topText: "Batch Videos",
          normalReplace: "batch videos",
          bottomText: "How other teachers are using batch videos?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/tkiMs7tUKbQ",
        },
      ];
    case featureTypeEnum.Notifications:
      return [
        {
          image: notificationsImg,
          topText: "Notifications",
          normalReplace: "notifications",
          bottomText: "What are notifications in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
          CTAtext: "Send Now",
        },
        {
          image: notificationsImg,
          topText: "Notifications",
          normalReplace: "notifications",
          bottomText: "How to use notifications for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
        {
          image: notificationsImg,
          topText: "Notifications",
          normalReplace: "notifications",
          bottomText: "How other teachers are using notifications?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
      ];
    case featureTypeEnum.Chats:
      return [
        {
          image: chatsImg,
          topText: "Chats",
          normalReplace: "chats",
          bottomText: "What are chats in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
          CTAtext: "Connect Now",
        },
        {
          image: chatsImg,
          topText: "Chats",
          normalReplace: "chats",
          bottomText: "How to use chat for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
        {
          image: chatsImg,
          topText: "Chats",
          normalReplace: "chats",
          bottomText: "How other teachers are using chat?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
      ];
    default:
      return [
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "What are assignments in your coaching app?",
          bgImg: Coursel1Bg1,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "How to use assignment for effective teaching?",
          bgImg: Coursel1Bg2,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
        {
          image: assignmentImg,
          topText: "Assignments",
          normalReplace: "assignments",
          bottomText: "How other teachers are using assignments?",
          bgImg: Coursel1Bg3,
          videoLink: "https://www.youtube.com/embed/a8E1n0XyCJM",
        },
      ];
  }
};

export const arrayReturn = (type) => {
  switch (type) {
    case "array1":
      return [
        {
          key: "1",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "2",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "3",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
      ];
    case "array2":
      return [
        {
          key: "4",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "5",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "6",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
      ];
    case "array3":
      return [
        {
          key: "7",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "8",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
        {
          key: "9",
          para: "Here goes a point of best practice. Each point of best practice can span a max of 4 lines. This is to fix the height of the card & prevent jankiness",
        },
      ];
    case featureTypeEnum.LiveClasses:
      return [
        {
          para: "We did about 4800 live classes and are really happier with the response from our students. Thanks to the Classplus team for this awesome feature.",
          orgName: "Infinity Coaching",
          orgBackgroundImg: orgBackgroundImg1,
          orgImage: infinityCoaching,
        },

        {
          para: "We loved the recorded lecture facility of the LIVE CLASS feature of our app as it ensures that no students miss the class.",
          orgName: "Bodhi Sattava Classes",
          orgBackgroundImg: orgBackgroundImg2,
          orgImage: bodhiSattava,
        },

        {
          para: "We have conducted 2000+ live classes for our students and shifted our coaching institute from offline to entirely online during the lockdown.",
          orgName: "Star Hybrid",
          orgBackgroundImg: orgBackgroundImg3,
          orgImage: starHybrid,
        },
        // {
        //   para:
        //     "The org’s testimonial comment goes here. This comment should span a maximum of four lines to keep it readable & the height of this card fixed. Testimonial ends here.",
        //   orgName: "Org Name goes here",
        //   orgBackgroundImg: orgBackgroundImg4
        // },
      ];
    case featureTypeEnum.Assignments:
      return [
        {
          para: "It is easy to track & analyze our students' performance by using the assignment feature. We can add multiple attachments to help students as well.",
          orgName: "Gyanin",
          orgBackgroundImg: orgBackgroundImg1,
          orgImage: gyanin,
        },

        {
          para: "Assignment have really helped my students to practice which keeps them updated on a regular basis.",
          orgName: "KK Commerce Academy",
          orgBackgroundImg: orgBackgroundImg2,
          orgImage: KKCommerceAcademy,
        },

        {
          para: "The assignment feature I can assign homework in the form of document, diagram and worksheets. Also I can track the submissions.",
          orgName: "Saraswati Science Tutorial",
          orgBackgroundImg: orgBackgroundImg3,
          orgImage: saraswatiScienceTutorial,
        },
        // {
        //   para:
        //     "The org’s testimonial comment goes here. This comment should span a maximum of four lines to keep it readable & the height of this card fixed. Testimonial ends here.",
        //   orgName: "Org Name goes here",
        //   orgBackgroundImg: orgBackgroundImg4
        // },
      ];
    case featureTypeEnum.OnlineTests:
      return [
        {
          para: " Test Portal has made it really easy to take tests and analyze the performance of my students. Thanks to Classplus for this awesome feature.",
          orgName: "Get99percentile",
          orgBackgroundImg: orgBackgroundImg1,
          orgImage: get99percentile,
        },

        {
          para: "Regular online tests and doubt solving groups helped us, teach students across India, along with analyzing their performances effectively.",
          orgName: "Synodic institute",
          orgBackgroundImg: orgBackgroundImg2,
          orgImage: synodicInstitute,
        },

        {
          para: "We have increased our frequency of assigning tests to 76%, which has helped our students to practice online for exams.",
          orgName: "Maths Made Easy",
          orgBackgroundImg: orgBackgroundImg3,
          orgImage: mathsMadeEasy,
        },
        // {
        //   para:
        //     "The org’s testimonial comment goes here. This comment should span a maximum of four lines to keep it readable & the height of this card fixed. Testimonial ends here.",
        //   orgName: "Org Name goes here",
        //   orgBackgroundImg: orgBackgroundImg4
        // },
      ];
    case featureTypeEnum.BatchVideos:
      return [
        {
          para: "We have uploaded more than 7000 videos for our students during lockdown to learn from home safely. Thanks to Classplus for this amazing feature.",
          orgName: "Badala Classes",
          orgBackgroundImg: orgBackgroundImg1,
          orgImage: badalaClasses,
        },

        {
          para: "We were able continue our coaching batches. Batch video have really helped our students a lot during this scenario. Thanks you Classplus.",
          orgName: "Mathspedia",
          orgBackgroundImg: orgBackgroundImg2,
          orgImage: mathsPedia,
        },

        {
          para: "We upload videos in the batch post every class which helps students to revise effectively post classes. Thank you Classplus for this feature.",
          orgName: "SAM Education Academy",
          orgBackgroundImg: orgBackgroundImg3,
          orgImage: SAMEducationAcademy,
        },
        // {
        //   para:
        //     "The org’s testimonial comment goes here. This comment should span a maximum of four lines to keep it readable & the height of this card fixed. Testimonial ends here.",
        //   orgName: "Org Name goes here",
        //   orgBackgroundImg: orgBackgroundImg4
        // },
      ];
    default:
      return [
        {
          para: "We did about 4800 live classes and are really happier with the response  from our students. Thanks to the Classplus team for this awesome feature.",
          orgName: "Infinity Coaching",
          orgBackgroundImg: orgBackgroundImg1,
          orgImage: infinityCoaching,
        },

        {
          para: "We loved the recorded lecture facility of the LIVE CLASS feature of our app as it ensures that no students miss the class.",
          orgName: "Bodhi Sattava Classes",
          orgBackgroundImg: orgBackgroundImg2,
          orgImage: bodhiSattava,
        },

        {
          para: "We have conducted 2000+ live classes for our students and shifted our coaching institute from offline to entirely online during the lockdown.",
          orgName: "Star Hybrid",
          orgBackgroundImg: orgBackgroundImg3,
          orgImage: starHybrid,
        },
        // {
        //   para:
        //     "The org’s testimonial comment goes here. This comment should span a maximum of four lines to keep it readable & the height of this card fixed. Testimonial ends here.",
        //   orgName: "Org Name goes here",
        //   orgBackgroundImg: orgBackgroundImg4
        // },
      ];
  }
};

export const numericValue = (value, token) => {
  switch (value) {
    case featureTypeEnum.LiveClasses:
      return `UTIL_WEBVIEW,https://dynamic-screen.classplusapp.com?featureType=1&token=${token}`;
    case featureTypeEnum.Assignments:
      return `UTIL_WEBVIEW,https://dynamic-screen.classplusapp.com?featureType=2&token=${token}`;
    case featureTypeEnum.OnlineTests:
      return `UTIL_WEBVIEW,https://dynamic-screen.classplusapp.com?featureType=3&token=${token}`;
    case featureTypeEnum.BatchVideos:
      return `UTIL_WEBVIEW,https://dynamic-screen.classplusapp.com?featureType=4&token=${token}`;
    case featureTypeEnum.Notifications:
      return `CREATE_NOTIFICATIONS,'',''`;
    case featureTypeEnum.Chats:
      return `SCREEN_CHATS,'',''`;
    default:
      return 1;
  }
};
