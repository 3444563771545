import React from "react";
import Subcoursel from "./subCoursel/Subcoursel";
import "../coursel/coursel.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Image
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
// import backgroundc from '../../Assets/cBackground1.png';

const Coursel = ({ data }) => {
  
  return (
    <div className="mainCoursel">
      <CarouselProvider
      isPlaying
      interval="3000"

        naturalSlideWidth={50}
        // naturalSlideHeight={50}
        isIntrinsicHeight = {true}
        totalSlides={3}
      >
        <Slider className="coursel-Main" 
        // style={{ height: "170px", borderRadius: "10px" }}
        >
          {data.map((item) => (
            <Slide item xs={6}>
          
              <Subcoursel
                para1={item.topText}
                para2={item.bottomText}
                videoLink={item.videoLink}
                image={item.image}
                backgroundImg={item.bgImg}
              />
            </Slide>
          ))}
        </Slider>
        <DotGroup />
      </CarouselProvider>
    </div>
  );
};

export default Coursel;
