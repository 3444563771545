import React from 'react'
import './Subcorousel_org.css'
import orgImage from '../../../utils/featureTypeData'

const Subcorousel_org = ({ data }) => {

const subcorouselOrgMainStyles = {
    
        backgroundImage: `url(${data.orgBackgroundImg})`,
      
        padding:'5%',
        backgroundPosition: "bottom",
        backgroundSize: "100% 100%",
        /* object-fit: fill; */
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        /* height: 200px; */
        width: "100%",
        marginTop: "5%",
        marginBottom: "25%",
        boxSizing:'border-box'
      
}

console.log(data.orgImage, "comp5")

    return (
        <div className="subcorouselorg-main" style={subcorouselOrgMainStyles}>
            <span id="comp5sub-text">{data.para}</span>
            <div className="comp5subOrg-container">
                <img src= {data.orgImage} id="orgImage"/>
                <span id="comp5sub-text">{data.orgName}</span>
            </div>
            
        </div>
    )
}

export default Subcorousel_org
