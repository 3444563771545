import React, { Component } from "react";
import "./App.css";
import appConfig from './appConfig';
import Component1 from "./components/coursel/Coursel";
import Component2 from "./components/component2/component2";
import Component3 from "./components/component3/Component3";
import Component4 from "./components/component4/Component4";
import Component5 from "./components/component5/Component5";

import * as featureTypeData from './utils/featureTypeData';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'semantic-ui-css/semantic.min.css'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: decodeURI(this.getQuery()["featureType"]),
      featureType: [],
      array1: featureTypeData.arrayReturn("array1"),
      array2: featureTypeData.arrayReturn("array2"),
      array3: featureTypeData.arrayReturn("array3"),
      coursel3Data: featureTypeData.arrayReturn(decodeURI(this.getQuery()["featureType"])),
      app: 0,
      performance: 0, 
      xAccessToken: this.getQuery().token,
    };

    // Deployment Link for staging:  feature-page.surge.sh

  }

  componentDidMount() {
    this.setState({
      type: decodeURI(this.getQuery()["featureType"]),
      featureType: featureTypeData.returnFeatureData(decodeURI(this.getQuery()["featureType"])),
      xAccessToken: this.getQuery().token,
    });
    this.getPerformanceDetails(this.parseToken(this.getQuery().token));

  }

  getPerformanceDetails = (details) => {
    fetch(`${appConfig.BASE_URL}/v3/featureOps?orgId=${details.orgId}&type=${decodeURI(this.getQuery()["featureType"])}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': this.state.xAccessToken
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.data) {
          this.setState({
            app: res.data.orgStat,
            performance: res.data.topPerformer
          })
        }
      })
      .catch((err) => toast.error(err.message));
  }

  getQuery = () => {
    return window.location.search
    .slice(1)
    .split("&")
    .map((qStr) => qStr.split("="))
    .reduce((acc, inc) => {
      acc[inc[0]] = inc[1];
      return acc;
    }, {});
  };

  parseToken = (token) => {
    return JSON.parse(
      token.replace(/^[^]+\.([^]+)\.[^]+$/, (token, payload) =>
        Buffer.from(payload, "base64").toString("ascii")
      )
    );
  };

  giveAssignmentToStudents = () => {
    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        featureTypeData.numericValue(decodeURI(this.getQuery()["featureType"]),this.state.xAccessToken)
      );
    }
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
      ) {
        window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
          featureTypeData.numericValue(decodeURI(this.getQuery()["featureType"]),this.state.xAccessToken)
        );
      }
  };

  helpAndSupport = (number) => {
    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_PHONE,${number},''`
      );
    }
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
      ) {
        window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
          `UTIL_PHONE,${number},''`
        );
      }
  };

  helpAndSupportWhatsapp = (message,number) => {
    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_WHATSAPP,${message},${number}`
      );
    }
    if (
      window &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onDeeplinkExecuted
      ) {
        window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
          `UTIL_WHATSAPP,${message},${number}`
        );
      }
  };



  render() {
    return (
      <>
        <ToastContainer autoClose={2000} />
        <Component1 data={this.state.featureType} />
        <Component2 data={this.state.featureType} app={this.state.app} performance={this.state.performance} />
        <Component3 data={this.state.featureType} array1={this.state.array1} array2={this.state.array2} array3={this.state.array3} />
        <Component4 data={this.state.featureType} helpAndSupport={(number) => this.helpAndSupport(number)} helpAndSupportWhatsapp={(message,number) => this.helpAndSupportWhatsapp(message,number)} />
        <Component5 data={this.state.featureType} comp5Data={this.state.coursel3Data} />
        <div className="bottom-div">
          <button id="bottom-cta" onClick={() => this.giveAssignmentToStudents()}>{this.state.featureType[0] && this.state.featureType[0].CTAtext}</button>
        </div>
      </>
    );
  }
}

export default App;
